import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Select from 'react-select';

import 'array-flat-polyfill';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';
import Container from '../components/global/container';
import CollectionsHeroImage from '../components/images/collections-hero-image';
import Product from '../components/product/product';

const CollectionsText = styled.div`
  margin-bottom: 100px;

  @media (max-width: 991px) {
    margin-bottom: 63px;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const CollectionsTitle = styled.h2`
  font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;
`;

const FiltersList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;

  text-transform: capitalize;

  @media (max-width: 992px) {
    justify-content: center;
  }

  @media (max-width: 768px) {
    margin: 0 0 30px 0;
  }

  & .filter-container:first-of-type {
    padding: 0 15px 30px 0;

    @media (max-width: 600px) {
      padding: 0 0 30px 0;
    }
  }

  & .filter-container:last-of-type {
    padding: 0 0 30px 15px;

    @media (max-width: 600px) {
      padding: 0 0 30px 0;
    }
  }

  & .filter-container {
    width: 250px;

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    & .dropdown__control {
      border: 0;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 0;

      min-height: 17px;

      cursor: pointer;
    }

    & .dropdown__control--is-focused {
      box-shadow: none;
    }

    & .dropdown__value-container {
      padding: 0;
      color: #e8e8e8;
    }

    & .dropdown__single-value,
    & .dropdown__placeholder {
      margin: 0;
      padding: 0 0 2px 0;
      color: #232323;

      position: relative;
      top: 0;
      transform: none;
    }

    & .dropdown__indicator-separator {
      display: none;
    }

    & .dropdown__indicator {
      padding: 0;

      & > svg {
        width: 10px;
        height: 10px;
        fill: #e8e8e8;
      }
    }

    & .dropdown__menu {
      border: 1px solid #e8e8e8;
      border-radius: 0;
      box-shadow: none;
    }

    & .dropdown__menu-list {
      padding: 0;
    }

    & .dropdown__option--is-selected {
      background-color: #e8e8e8;
    }

    & .dropdown__option--is-focused {
      background-color: #e8e8e8;
    }

    & .css-b8ldur-Input {
      margin: 0;
      padding: 0;
    }
  }
`;

const Collection = ({ data, location }) => {
  const allProducts = data.shopifyCollection.products;
  const [products, setProducts] = useState(data.shopifyCollection.products);
  const [activeFilter, saveActiveFilter] = useState(`All`);
  const [activeSortBy, setActiveSortBy] = useState('');

  const filters = Array.from(
    new Set(data.shopifyCollection.products.map(product => product.tags).flat())
  ).map(filter => (filter = { value: filter, label: filter }));

  filters.unshift({ value: `All`, label: `All` });

  const setActiveFilter = filter => {
    if (filter === 'All') {
      setProducts(allProducts);
      saveActiveFilter(filter);
    } else {
      setProducts(allProducts.filter(product => product.tags.includes(filter)));
      saveActiveFilter(filter);
    }
  };

  const sortOptions = [
    {
      value: 'price-asc',
      label: 'Price Ascending',
    },
    {
      value: 'price-desc',
      label: 'Price Descending',
    },
  ];

  const updateSortBy = incomingSort => {
    setActiveSortBy(incomingSort);
  };

  useEffect(() => {
    if (activeSortBy === 'price-desc') {
      setProducts(
        products.sort(
          (a, b) =>
            parseInt(a.priceRangeV2.maxVariantPrice.amount) -
            parseInt(b.priceRangeV2.maxVariantPrice.amount)
        )
      );
    }

    if (activeSortBy === 'price-asc') {
      setProducts(
        products.sort(
          (a, b) =>
            parseInt(b.priceRangeV2.maxVariantPrice.amount) -
            parseInt(a.priceRangeV2.maxVariantPrice.amount)
        )
      );
    }
  }, [activeSortBy]);

  const firstTwoProducts = products
    .filter((product, index) => index < 2)
    .map(product => (
      <Product
        data={product}
        key={product.id}
        collection={location.pathname}
        type={'normal'}
      />
    ));

  const remainingProducts = products
    .filter((product, index) => index >= 2)
    .map(product => (
      <Product
        data={product}
        key={product.id}
        collection={location.pathname}
        type={'normal'}
      />
    ));

  let seoImage;
  if (data.shopifyCollection.image !== null) {
    seoImage = data.shopifyCollection.image.src;
  } else {
    seoImage = null;
  }

  return (
    <>
      <Helmet
        title={`${data.shopifyCollection.title} – Mourne Textiles`}
        meta={[
          {
            name: 'description',
            content: `${data.shopifyCollection.descriptionHtml.substring(
              0,
              240
            )}...`,
          },
          {
            name: 'og:image',
            content: `${seoImage}`,
          },
          {
            name: 'og:image:secure_url',
            content: `${seoImage}`,
          },
          {
            name: 'og:description',
            content: `${data.shopifyCollection.descriptionHtml.substring(
              0,
              240
            )}...`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `Mourne Textiles`,
          },
          {
            name: 'twitter:description',
            content: `${data.shopifyCollection.descriptionHtml.substring(
              0,
              240
            )}...`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${seoImage}`,
          },
        ]}
      />

      {data.shopifyCollection.image !== null && (
        <CollectionsHeroImage image={seoImage} />
      )}

      <Container marginTop={'60px'}>
        <Row>
          <Col col={12} order={2} mdOrder={2} lgOrder={1}>
            <Row justifyContent="center" alignItems="center">
              <Col col={12}>
                <FiltersList>
                  <div className="filter-container">
                    <Select
                      options={filters}
                      className="filters-dropdown"
                      classNamePrefix="dropdown"
                      value={filters.filter(
                        option => option.value === activeFilter
                      )}
                      onChange={e => setActiveFilter(e.label)}
                    />
                  </div>
                  <div className="filter-container">
                    <Select
                      options={sortOptions}
                      className="filters-dropdown"
                      classNamePrefix="dropdown"
                      placeholder="Sort by price"
                      value={sortOptions.filter(
                        option => option.value === activeSortBy
                      )}
                      onChange={e => updateSortBy(e.value)}
                    />
                  </div>
                </FiltersList>
              </Col>
            </Row>
          </Col>

          <Col col={12} lg={6} order={1} mdOrder={1} lgOrder={2}>
            <Row justifyContent="center" alignItems="center">
              <Col col={12} md={9} lg={10}>
                <CollectionsTitle>
                  {data.shopifyCollection.title}
                </CollectionsTitle>

                <CollectionsText
                  dangerouslySetInnerHTML={{
                    __html: data.shopifyCollection.descriptionHtml,
                  }}
                />
              </Col>
            </Row>
          </Col>
          {firstTwoProducts}

          {remainingProducts}
        </Row>
      </Container>
    </>
  );
};

export const query = graphql`
  query CollectionQuery($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      title
      descriptionHtml
      image {
        src
      }
      handle
      products {
        id
        handle
        title
        featuredImage {
          originalSrc
        }
        tags
        totalInventory
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;

export default Collection;
